import { Box, styled, Grid, Typography, Collapse } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../api';
import Loader from '../../../components/Loader';
import useFetch from '../../../hooks/useFetch';
import { fetchLocations, setActiveClientLocation } from '../../../redux/reducers/clientLocationSlice';
import {
    FORCE_RESOLUTION_TAB,
    FRAME_RATE,
    MONITOR_OVERSCAN_TAB,
    ORIENTATION,
    PORTRAIT_BOTTOM,
    RESOLUTION_FORMAT_TYPE,
    RESOLUTION_TAB
} from '../../../utils/constants';
import { filterMirrorLocation, getTheDefaultLayout, sortCategoryList } from '../../../utils/helpers';
import { COLORS } from '../../../utils/theme';
import { CommonHeader } from '../../../widgets/CommonHeader';
import { Advanced } from './components/Advanced';
import { Orientation } from './components/Orientation';
import { Resolution } from './components/Resolution';
import { Zones } from './components/Zones';
import NoDataFound from '../../../components/NoDataFound';
import { setOrientation } from '../../../redux/reducers/orientationSlice';
import { AccessControlledComponent } from '../../../components/AccessControlledComponent';
import { fetchClientAccess } from '../../../redux/reducers/authSlice';

const MainSectionRowView = styled(Grid)({
    border: `1px solid ${COLORS.border}`,
    marginTop: 12,
    backgroundColor: COLORS.white,
    borderRadius: '.425rem',
    boxShadow: COLORS.cardShadow,
    cursor: 'pointer'
});
const MainSectionRowItem = styled(Grid)({
    borderTopLeftRadius: '.425rem',
    borderTopRightRadius: '.425rem'
});

const SectionNameGrid = styled(Grid)({
    display: 'flex',
    padding: 10
});

const SectionNameText = styled(Typography)({
    fontSize: 16
});

const initialLayoutItems = [
    {
        id: 'orientation',
        name: 'Orientation and Zones'
    },
    {
        id: 'resolution',
        name: 'Resolution'
    },
    {
        id: 'advanced',
        name: 'Advanced'
    }
];

export const ClientLayout = () => {
    const [loading, setLoading] = useState(false);
    const [expandedDropDown, setExpandedDropDown] = useState();
    const [unSavedChange, setUnsavedChanges] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { locations, activeLocation } = useSelector((state) => state.clientLocation);
    const { user } = useSelector((state) => state?.auth);
    const id = user?.client?.id;
    const locationList = filterMirrorLocation(sortCategoryList(locations));
    const [layouts, setLayouts] = useState([]);

    const {
        data: layoutData,
        callFetch: fetchLayouts,
        isLoading: isLayoutLoading
    } = useFetch({
        initialUrl: `/get_layout_admin`,
        skipOnStart: true
    });

    useEffect(() => {
        if (layoutData?.result) {
            setLayouts(layoutData?.result);
            handleUpdateDefaultLayouts(layoutData?.result);
        }
    }, [layoutData?.result]);

    const {
        data: layoutInfo,
        callFetch: fetchLayoutInfo,
        isLoading
    } = useFetch({
        initialUrl: `/get_client_layout`,
        skipOnStart: true
    });

    useEffect(() => {
        if (locationList.length && !activeLocation) {
            dispatch(setActiveClientLocation(locationList[0]?.id));
        } else {
            let ifExist = locationList.some((el) => el.id === activeLocation);
            if (!ifExist) dispatch(setActiveClientLocation(locationList[0]?.id));
        }
    }, [locationList]);

    useEffect(() => {
        if (activeLocation) {
            fetchLayoutInfo({ params: { location_id: activeLocation } });
        }
    }, [fetchLayoutInfo, activeLocation]);

    useEffect(() => {
        dispatch(fetchLocations(id));
    }, [dispatch, id]);
    const handleCollapseSections = (item) => {
        if (item.id === expandedDropDown) {
            setExpandedDropDown(null);
        } else {
            setExpandedDropDown(item.id);
        }
    };

    const { values, handleSubmit, setFieldValue, setValues } = useFormik({
        initialValues: {
            orientation: ORIENTATION.LANDSCAPE,
            portraitBottom: PORTRAIT_BOTTOM.LEFT,
            resolutionInfo: {
                resolution: RESOLUTION_TAB[0].id,
                frame_rate: FRAME_RATE[RESOLUTION_TAB[0].id].id,
                format: RESOLUTION_FORMAT_TYPE.NTSC
            },
            layout_id: null,
            advanceInfo: {
                bg_color: COLORS.primary.light,
                monitor_overscan: MONITOR_OVERSCAN_TAB[0].id,
                force_resolution: FORCE_RESOLUTION_TAB[0].id
            }
        },
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const reqData = {
                    orientation: values.orientation,
                    resolution: values.resolutionInfo.resolution,
                    frame_rate: values.resolutionInfo.frame_rate,
                    format: values?.resolutionInfo?.format,
                    bg_color: values.advanceInfo.bg_color,
                    monitor_overscan: values.advanceInfo.monitor_overscan,
                    force_resolution: values.advanceInfo.force_resolution,
                    location_id: activeLocation,
                    portrait_bottom: values.portraitBottom,
                    layout_id: values.layout_id
                };
                const { data } = await api.layout.addClientLayoutInfo(reqData);
                setLoading(false);
                if (data.success) {
                    enqueueSnackbar(data.message, { variant: 'success' });
                    dispatch(setOrientation(values.orientation));
                    dispatch(fetchClientAccess({ params: { client_id: id } }));
                    setUnsavedChanges(false);
                } else {
                    enqueueSnackbar(data.message, { variant: 'error' });
                }
            } catch (error) {
                setLoading(false);
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        }
    });

    useEffect(() => {
        fetchLayouts({ params: { orientation: values.orientation, status: 1 } });
    }, [fetchLayouts, values.orientation]);

    useEffect(() => {
        if (layoutInfo?.result) {
            setValues({
                orientation: layoutInfo?.result?.orientation,
                portraitBottom: layoutInfo?.result?.portrait_bottom,
                resolutionInfo: {
                    resolution: layoutInfo?.result?.resolution,
                    frame_rate: layoutInfo?.result?.frame_rate,
                    format: layoutInfo?.result?.format || RESOLUTION_FORMAT_TYPE.NTSC
                },
                layout_id: layoutInfo?.result?.layout_id,
                advanceInfo: {
                    bg_color: layoutInfo?.result?.bg_color,
                    monitor_overscan: layoutInfo?.result?.monitor_overscan,
                    force_resolution: layoutInfo?.result?.force_resolution
                }
            });
        }
    }, [layoutInfo?.result, setValues]);

    const handleChangeField = (key, value) => {
        setUnsavedChanges(true);
        setFieldValue(key, value);
    };

    useEffect(() => {
        const framRates = FRAME_RATE[values?.resolutionInfo?.resolution]?.filter((item) => item?.format === values?.resolutionInfo?.format);
        if (framRates.findIndex((item) => item?.id === values?.resolutionInfo?.frame_rate) === -1) {
            let resolutionInfo = {
                ...values.resolutionInfo,
                frame_rate: framRates[0].id
            };
            setFieldValue('resolutionInfo', resolutionInfo);
        }
    }, [values.resolutionInfo?.resolution, values?.resolutionInfo?.format]);

    // useEffect(() => {
    //     const framRates = FRAME_RATE[values?.resolutionInfo?.resolution]?.filter((item) => item?.format === values?.resolutionInfo?.format);

    //     let resolutionInfo = {
    //         ...values.resolutionInfo,
    //         frame_rate: framRates[0]?.id
    //     };
    //     setFieldValue('resolutionInfo', resolutionInfo);
    // }, [values.resolutionInfo?.resolution, values?.resolutionInfo?.format]);

    useEffect(() => {
        if (values?.orientation === ORIENTATION?.PORTRAIT && values.resolutionInfo?.resolution === '4k') {
            let resolutionInfo = {
                ...values.resolutionInfo,
                resolution: '1080p'
            };
            setFieldValue('resolutionInfo', resolutionInfo);
        }
    }, [values?.orientation]);

    const handleUpdateDefaultLayouts = (currentLayouts) => {
        if (currentLayouts?.length) {
            if (currentLayouts.findIndex((item) => item?.id === values.layout_id) === -1) {
                const defaultLayout = getTheDefaultLayout(currentLayouts);
                if (defaultLayout) {
                    setFieldValue('layout_id', defaultLayout?.id);
                }
            }
        }
    };

    const SectionEelementById = ({ section }) => {
        switch (section?.id) {
            case 'orientation':
                return (
                    <Fragment>
                        <Orientation
                            defaultValue={values.orientation}
                            portraitBottom={values.portraitBottom}
                            setFieldValue={handleChangeField}
                        />
                        <Zones
                            {...{ layouts, isLoading: isLayoutLoading }}
                            defaultValue={values.layout_id}
                            setFieldValue={handleChangeField}
                            orientation={values.orientation}
                        />
                    </Fragment>
                );
            case 'resolution':
                return (
                    <Resolution defaultValue={values.resolutionInfo} setFieldValue={handleChangeField} orientation={values.orientation} />
                );
            case 'zones':
                return (
                    <Zones
                        layouts={layouts}
                        defaultValue={values.layout_id}
                        setFieldValue={handleChangeField}
                        orientation={values.orientation}
                    />
                );
            case 'advanced':
                return <Advanced defaultValue={values.advanceInfo} setFieldValue={handleChangeField} />;
            default:
                return null;
        }
    };
    const handleChangedLocation = (e) => {
        dispatch(setActiveClientLocation(e.target.value));
    };

    return (
        <Box>
            <CommonHeader
                title={'Layout'}
                buttonLabel={'Save'}
                dropdownValue={activeLocation}
                loading={loading}
                dropdownList={locationList}
                outlineInputClass={{ top: 0 }}
                unsavedChanges={unSavedChange}
                handleChangeDropdown={handleChangedLocation}
                onClick={handleSubmit}
            />
            <Box sx={{ marginTop: '24px' }}>
                {isLoading && <Loader isLoading={isLoading} />}
                {initialLayoutItems.length ? (
                    initialLayoutItems.map((section, index) => {
                        return (
                            <AccessControlledComponent accessValue={section.id}>
                                <MainSectionRowView key={section.id} container>
                                    <MainSectionRowItem
                                        sx={section.id === expandedDropDown && { backgroundColor: COLORS.primary.light }}
                                        onClick={() => {
                                            handleCollapseSections(section);
                                        }}
                                        container
                                    >
                                        <SectionNameGrid item md={12}>
                                            <SectionNameText sx={section.id === expandedDropDown && { color: COLORS.white }}>
                                                {section.name}
                                            </SectionNameText>
                                        </SectionNameGrid>
                                    </MainSectionRowItem>
                                    <Collapse sx={{ width: '100%' }} in={section.id === expandedDropDown}>
                                        {SectionEelementById({ section: section })}
                                    </Collapse>
                                </MainSectionRowView>
                            </AccessControlledComponent>
                        );
                    })
                ) : (
                    <NoDataFound message={'Location Not Found.'} />
                )}
            </Box>
        </Box>
    );
};
