import { Box, styled } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { api } from '../../../api';
import {
    addSheduledLoopData,
    clearLoopFormData,
    fetchAllContent,
    fetchCustomLoopAndUpdatePlaylist,
    fetchLoopTemplateAndUpdatePlaylist,
    updateLoopFormDataByKey
} from '../../../redux/reducers/loopSlice';
import { LOOP_CREATE_TYPE, LOOP_SCHEDULE_TYPE, ORIENTATION } from '../../../utils/constants';
import { filterMirrorLocation, getOriantationByLayout, sortCategoryList } from '../../../utils/helpers';
import { CommonHeader } from '../../../widgets/CommonHeader';
import { AlertDialog } from '../../../widgets/CommonWidgets';
import { LoopChart } from './components/LoopChart';
import { fetchLocations, setActiveClientLocation } from '../../../redux/reducers/clientLocationSlice';
import NoDataFound from '../../../components/NoDataFound';
import Loader from '../../../components/Loader';
import { isEqual } from 'lodash';

const MainBox = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
});

export const LoopTemplateSchedule = () => {
    const pathName = useLocation().pathname;
    const { state } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loopFormData } = useSelector((state) => state.loop);
    const { locations, activeLocation } = useSelector((state) => state.clientLocation);
    const locationList = filterMirrorLocation(sortCategoryList(locations));
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const isInTemplate = pathName.includes('template');
    const isInTemplateEdit = isInTemplate && pathName.includes('edit');
    const isInCustomEdit = !isInTemplate && id;
    const [libraryFatched, setLibraryFatched] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isLoopLoading, setLoopLoading] = useState(true);
    const [showDeletePopup, setDeletePopup] = useState(false);
    const [showResetPopup, setResetPopup] = useState({ type: '', isOpen: false });
    const { id: clientId } = useSelector((state) => state?.auth?.user?.client);

    useEffect(() => {
        dispatch(fetchLocations(clientId));
    }, [dispatch, clientId]);
    useEffect(() => {
        const isActiveLocationExist = locationList.some((el) => el.id === activeLocation);
        const isLocationIdExist = locationList.some((el) => el.id === state?.location_id);
        if (isInTemplateEdit || isInCustomEdit) {
            dispatch(setActiveClientLocation(isLocationIdExist ? state?.location_id : ''));
        } else {
            if (!isActiveLocationExist) {
                dispatch(setActiveClientLocation(locationList[0]?.id));
            }
        }
    }, [clientId, locationList, dispatch, isInCustomEdit, isInTemplateEdit, state?.location_id]);

    const orientation = useMemo(() => {
        if (locations.length !== 0) {
            let activeLocationData = locations.find((item) => item?.id === activeLocation);
            return getOriantationByLayout(activeLocationData?.layout);
        }
        return ORIENTATION.LANDSCAPE;
    }, [locations, activeLocation]);

    useEffect(() => {
        if ((isInTemplate || isInTemplateEdit) && id && libraryFatched) {
            if (state.template_id) {
                dispatch(fetchCustomLoopAndUpdatePlaylist({ params: { id: state.template_id } })).then(() => setLoopLoading(false));
            } else {
                dispatch(fetchLoopTemplateAndUpdatePlaylist({ params: { loop_template_id: id } })).then(() => setLoopLoading(false));
            }
        }
    }, [dispatch, id, isInTemplate, libraryFatched, isInTemplateEdit]);

    useEffect(() => {
        if (isInCustomEdit && id && libraryFatched) {
            setLoopLoading(true);
            dispatch(fetchCustomLoopAndUpdatePlaylist({ params: { id: id } })).then(() => setLoopLoading(false));
        }
    }, [dispatch, id, isInCustomEdit, libraryFatched, isInTemplateEdit]);

    useEffect(() => {
        return () => {
            dispatch(clearLoopFormData());
            dispatch(addSheduledLoopData([]));
        };
    }, []);

    useEffect(() => {
        if (orientation) {
            let params = {
                orientation: orientation,
                status: 1
            };
            dispatch(fetchAllContent({ params })).then((e) => {
                setLibraryFatched(true);
            });
        }
    }, [dispatch, orientation]);
    const handleSubmit = async () => {
        if (!loopFormData?.playList.length) {
            enqueueSnackbar('A Loop must contain a minimum of one Playlist.', { variant: 'error' });
        } else {
            try {
                setLoading(true);
                let reqData = {
                    loop_type: isInTemplate ? LOOP_CREATE_TYPE.TEMPLATE : LOOP_CREATE_TYPE.CUSTOM,
                    location_id: isInTemplateEdit || isInCustomEdit ? loopFormData?.location_id : activeLocation,
                    name: loopFormData?.name,
                    categories: loopFormData?.playList.map((item) => {
                        return {
                            category_id: item.id,
                            unique_id: item?.uniqId,
                            mode: item?.mode,
                            playback_method: item?.playback_method,
                            name: item?.name,
                            contents: item?.selectedContents?.map((video) => {
                                return {
                                    content_id: video?.id,
                                    unique_id: video?.uniqId || Math.random().toString(16).slice(2)
                                };
                            })
                        };
                    })
                };
                if (!isEqual(loopFormData.old_schedule, loopFormData.schedule) && loopFormData.schedule.length) {
                    if (loopFormData?.schedule?.[0]?.type === 'custom') {
                        reqData.schedule = [
                            {
                                type: loopFormData?.schedule?.[0]?.type,
                                event_type: loopFormData?.schedule?.[0]?.eventType,
                                start_date_time: loopFormData?.schedule?.[0]?.startDateTime,
                                end_date_time: loopFormData?.schedule?.[0]?.endDateTime,
                                recurrence_pattern: loopFormData?.schedule?.[0]?.recurrencePattern,
                                loop_schedules: loopFormData?.schedule?.[0]?.loop_schedules,
                                days: loopFormData?.schedule?.[0]?.days
                            }
                        ];
                    } else {
                        reqData.schedule = loopFormData.schedule;
                    }
                }
                if (loopFormData.schedule?.length === 0) {
                    reqData.schedule = [];
                }
                if (isInTemplate && !isInTemplateEdit) {
                    reqData.template_id = +id;
                }
                reqData.is_primary = +loopFormData?.is_primary || 0;
                if (loopFormData?.schedule?.[0]?.type === LOOP_SCHEDULE_TYPE.ALL_DAY) {
                    reqData.is_primary = 1;
                } else {
                    reqData.is_primary = 0;
                }
                let result;
                if (isInCustomEdit || isInTemplateEdit) {
                    reqData.id = isInTemplateEdit ? +state.template_id : +id;
                    // reqData.id = +id;
                    const { data } = await api.loop.editLoopSchedule(reqData);
                    result = data;
                } else {
                    const { data } = await api.loop.addLoopSchedule(reqData);
                    result = data;
                }

                setLoading(false);
                if (result.success) {
                    handleClose();
                    enqueueSnackbar(result.message, { variant: 'success' });
                } else {
                    enqueueSnackbar(result.message, { variant: 'error' });
                }
            } catch (error) {
                setLoading(false);
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        }
    };
    const handleClose = () => {
        navigate(-1);
        dispatch(clearLoopFormData());
    };
    const deleteCustomLoop = async () => {
        try {
            const { data } = await api.loop.deleteLoop(id);
            if (data.success) {
                handleClose();
                enqueueSnackbar(data.message, { variant: 'success' });
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };
    const resetChanges = () => {
        setLoopLoading(true);
        if (showResetPopup.type === 'loop') {
            if (state.template_id) {
                dispatch(fetchCustomLoopAndUpdatePlaylist({ params: { loop_template_id: state.template_id } })).then(() =>
                    setLoopLoading(false)
                );
            } else {
                dispatch(fetchLoopTemplateAndUpdatePlaylist({ params: { loop_template_id: id } })).then(() => setLoopLoading(false));
            }
            dispatch(updateLoopFormDataByKey({ key: 'activePlaylist', value: null }));
            dispatch(updateLoopFormDataByKey({ key: 'schedule', value: {} }));
            dispatch(updateLoopFormDataByKey({ key: 'type', value: '' }));
        } else {
            dispatch(updateLoopFormDataByKey({ key: 'playList', value: [] }));
            setLoopLoading(false);
        }
        setResetPopup({ type: '', isOpen: false });
    };
    return (
        <Box>
            <CommonHeader
                title={isInTemplate ? 'Loop Template' : 'Custom Loop'}
                buttonLabel={'Save'}
                loading={loading}
                onClick={handleSubmit}
                inEdit={isInCustomEdit && activeLocation}
                onDelete={() => setDeletePopup(true)}
                onClose={handleClose}
                breadcrumbText={id ? 'Edit' : 'Add'}
                backTo={'Loops'}
                isSaveButtonDisabled={!activeLocation}
                // onReset={isInTemplate ? () => setResetPopup({ type: 'loop', isOpen: true }) : null}
                unsavedChanges={id ? loopFormData?.unsavedChanges : false}
            />
            <Box sx={{ marginTop: '24px' }}>
                {(isInCustomEdit || isInTemplateEdit || isInTemplate) && isLoopLoading ? (
                    <Loader isLoading={isLoopLoading} />
                ) : activeLocation ? (
                    <MainBox>
                        <LoopChart
                            isInTemplate={isInTemplate}
                            isInCustomEdit={isInCustomEdit}
                            onReset={() => setResetPopup({ type: 'playlist', isOpen: true })}
                            reset={!isInTemplate && loopFormData.playList.length > 0}
                            orientation={orientation}
                        />
                    </MainBox>
                ) : (
                    <NoDataFound message={'Location Not Found.'} />
                )}
            </Box>

            {showDeletePopup ? (
                <AlertDialog
                    isOpen={showDeletePopup}
                    isClose={() => setDeletePopup(false)}
                    handleSubmit={deleteCustomLoop}
                    title={'Delete Custom Loop'}
                    description={'Are you sure you want to delete this custom loop ?'}
                    isCancel={'No'}
                />
            ) : null}
            {showResetPopup.isOpen ? (
                <AlertDialog
                    isOpen={showResetPopup.isOpen}
                    isClose={() => setResetPopup({ type: 'loop', isOpen: false })}
                    handleSubmit={resetChanges}
                    title={'Reset Loop'}
                    description={
                        showResetPopup.type === 'loop'
                            ? 'Are you sure you want to reset this Loop? All changes will be lost.'
                            : 'Are you sure you want to reset this Loop? All Playlists will be removed from the Loop.'
                    }
                    isCancel={'No'}
                />
            ) : null}
        </Box>
    );
};
